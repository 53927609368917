import React from "react";
import FormAccount from "../components/form-account";

const PageAccount = () => {
  return (
    <article
      className={`node-intern node-full-page`}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Account</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-8">
            <FormAccount />
          </div>
        </div>
      </div>
    </article>
  );
};

export default PageAccount;
