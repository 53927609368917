import React, { useEffect } from "react";
import { removeToast } from "../store/toast-store";
import { motion } from "framer-motion";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

const Toast = ({ toast, duration }) => {
  useEffect(() => {
    const timer = setTimeout(() => removeToast(toast.id), duration);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <motion.div
      data-id={toast.id}
      className={classNames({
        "toast": true,
        [toast.type]: toast.type,
      })}
      layout
      initial={{ opacity: 0, x: 150 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 150 }}
    >
      <div className={"toast-header"}>
        <span><FormattedMessage id={`toast.${toast.type}`} /></span>
        <button onClick={() => removeToast(toast.id)} className={"toast-close"}>✖</button>
      </div>
      <div className={"toast-body"}>{toast.message}</div>
    </motion.div>
  );
};

export default Toast;