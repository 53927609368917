import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../../general-components/loading-indicator";
import ContentBase from "../../general-components/content-base/content-base";
import landingPageQuery from "./../../landingpage/landingpage.graphql";
import { i18nActionHelper } from "./../../i18n/i18n";
import Error404 from "../../system/404";
import { Redirect } from "react-router-dom";
import ExecutionEnvironment from "exenv";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
  pagerConfig: reduxStore.appStore.pagerFullPages,
  microSite: reduxStore.appStore.microSite,
  microSiteFrontPage: reduxStore.appStore.microSiteFrontPage,
  auth: reduxStore.auth,
  adminApp: reduxStore.appStore.adminApp,
});

const PageIntern = (props) => {
  const location = useLocation();
  const adminApp = useSelector((state) => state.appStore.adminApp);
  const auth = useSelector((state) => state.auth);
  const microSite = useSelector((state) => state.appStore.microSite);
  const microSiteFrontPage = useSelector(
    (state) => state.appStore.microSiteFrontPage
  );

  const dispatch = useDispatch();

  const { data, loading } = useQuery(landingPageQuery, {
    variables: {
      path:
        location.pathname === "/" && microSite && microSiteFrontPage !== ""
          ? microSiteFrontPage
          : location.pathname,
    },
  });

  useEffect(() => {
    i18nActionHelper(props, dispatch);
  }, [data?.route])

  if (
    !adminApp &&
    !auth.userLoginData.logout_token &&
    ExecutionEnvironment.canUseDOM
  ) {
    return <Redirect to={"/intern/login?destination=" + location.pathname} />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!data?.route) {
    return <Error404 />;
  }

  const published = data.route.entity.status
    ? "node-published"
    : "node-unpublished";

  return (
    <>
      <Helmet>
        <title>{`${data.route.entity.entityLabel} | Dr. Malek Logistiksoftware`}</title>
      </Helmet>
      <article
        className={`node-intern node-${data.route.entity.entityId} node-full-page ${published}`}
      >
        {data.route.entity.fieldModules && (
          <ContentBase
            content={data.route.entity.fieldModules}
            nodeContent={data.route.entity}
          />
        )}
      </article>
    </>
  );
};

PageIntern.propTypes = {
  auth: PropTypes.object,
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
};

export default connect(mapStateToProps)(
  graphql(landingPageQuery, {
    options: (props) => ({
      variables: {
        path:
          props.location.pathname === "/" &&
          props.microSite &&
          props.microSiteFrontPage !== ""
            ? props.microSiteFrontPage
            : props.location.pathname,
      },
    }),
  })(withRouter(PageIntern))
);
