import React from "react";
import FormForgotPassword from "../components/form-forgot-password";
import ExecutionEnvironment from "exenv";
import { Redirect } from "react-router-dom";
import useAuthentication from "../use-authentication";

const PageForgotPassword = () => {
  const { auth } = useAuthentication();

  // Redirect to dashboard if user is already logged in.
  if (auth.userLoginData.logout_token && ExecutionEnvironment.canUseDOM) {
    return <Redirect to="/intern/start" />;
  }

  return (
    <article className="system-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text">
            <h1>Password zurücksetzen</h1>
            <p>
              Bitte geben Sie Ihre E-Mail-Adresse an, mit der Sie registriert
              sind. Sie erhalten dann eine Email mit weiteren Anweisungen zum
              Zurücksetzen Ihres Passworts.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 offset-md-2 col-lg-4 offset-lg-3">
            <FormForgotPassword />
          </div>
        </div>
      </div>
    </article>
  );
};

export default PageForgotPassword;
