import React from "react";
import { useLocation } from "react-router-dom";

const usePageContext = () => {
  const { pathname } = useLocation();

  if (pathname.startsWith("/intern")) {
    return "intern";
  } else {
    return "public";
  }
};

export default usePageContext;
