import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import UserMenu from "../components/user-menu";
import PageIntern from "./page-intern";
import Blog from "../../blog/blog";
import ErrorBoundary from "../../error-boundary";
import UserStatusBlock from "../components/user-status-block";
import PageAccount from "./page-account";

const InternLayout = () => {
  const { path } = useRouteMatch();

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-3">
          <div className="sidebar">
            <UserMenu />
            <div className="card-user-status">
              <UserStatusBlock ignoreOfflineState={true} />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-9">
          <ErrorBoundary>
            <Switch>
              {/* <Route path={`${path}/account`} component={PageAccount} /> */}
              <Route path={`${path}/blog/:alias`} component={Blog} />
              <Route path={`${path}/:alias`} component={PageIntern} />
            </Switch>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default InternLayout;
