import React from "react";

const PageVerifyRegistration = () => {
  return (
    <article className="system-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="text">
              <h1>Überprüfen Sie Ihr E-Mail-Postfach</h1>
              <p>
                Bitte bestätigen Sie Ihre Registrierung über den Link, den wir
                Ihnen soeben per E-Mail gesendet haben.
                <br />
                Überprüfen Sie auch Ihren Spam-Ordner.
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default PageVerifyRegistration;
