import React from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import FieldTime from "../../date-time/field-time";

const TeaserNews = (props) => {
  const { adminApp } = useSelector((reduxStore) => ({
    adminApp: reduxStore.appStore.adminApp,
  }));

  return (
    <article className="node node-teaser teaser-news">
      <EditButton
        adminApp={adminApp}
        entityId={props.item.entityId}
        destinationRoute={props.location.pathname}
      />
      {!!props.item.fieldType?.length && (
        <div className="banner">
          {props.item.fieldType?.map(type => (type?.entity?.name)).join(", ")}
        </div>
      )}
      <div className="row">
        <div className="col-16 col-sm-8 order-2 order-sm-1">
          <small>
            <FieldTime date={props.item.fieldDate} />
          </small>
          {props.item.fieldSchlagwort.length > 0 &&
            props.item.fieldSchlagwort[0].entity && (
              <span className="top-line">
                {props.item.fieldSchlagwort[0].entity.name}
              </span>
            )}

          <h3>{props.item.title}</h3>
          {props.item.fieldTeasertext && (
            <div
              className="teaser text"
              dangerouslySetInnerHTML={{
                __html: props.item.fieldTeasertext.processed,
              }}
            />
          )}
        </div>
        {props.item.fieldTeaserbild && (
          <div
            className={`col-16 col-sm-4 order-1 order-sm-2 ${props.item.fieldTeaserbild.entity.entityBundle}`}
          >
            <Image
              data={props.item.fieldTeaserbild.entity.fieldMediaImage}
              nodeTitle={props.item.title}
            />
          </div>
        )}
      </div>
    </article>
  );
};

export const teaserNewsPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  publishedAt: PropTypes.shape({
    value: PropTypes.number,
    publishedAtOrNow: PropTypes.number,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTeasertext: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldTeaserbild: PropTypes.shape({
    entity: PropTypes.shape({
      entityBundle: PropTypes.string,
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  fieldDate: PropTypes.object,
});

TeaserNews.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserNewsPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default withRouter(TeaserNews);
