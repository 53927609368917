import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

const CaptchaField = () => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    window.turnstile.render("#cf-turnstile", {
      sitekey: "0x4AAAAAAAz73TxXiNj1ftLM",
      callback: (token) => {
        setFieldValue("captcha", token);
      },
    })
  }, [])

  return (
    <div id="cf-turnstile" />
  );
};

CaptchaField.propTypes = {};

export default CaptchaField;
