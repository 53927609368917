import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../../teaser-base/news/teaser-news";
import { teaserEventPropTypes } from "../../../../teaser-base/event/teaser-event";
import { teaserPersonPropTypes } from "../../../../teaser-base/person/teaser-person";
import { teaserGeneralPropTypes } from "../../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../../teaser-base/project/teaser-projekt";
import Loader from "react-loader-spinner";

import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import { useQuery } from "@apollo/client";
import TeaserBlog from "../../../../teaser-base/blog/teaser-blog";
import ReactPaginate from "react-paginate";

const ParagraphTeaserListBlog = ({ content }) => {
  const isManual = content.fieldAutmatischOderManuell === "Manuell";
  const [currentOffset, setCurrentOffset] = useState(0);

  const { data, loading, error } = useQuery(teaserNodeQueryFilterTag, {
    skip: isManual,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: content.fieldAnzahlDerAnzuzeigendenI || 100,
      type: ["blog"],
      tag: content.fieldSchlagwort,
      filterTagEnabled: content.fieldSchlagwort > 0,
      sortField: "field_date.value",
      sortDirection: "DESC",
      offset: currentOffset,
    },
  });

  const nodes = data?.nodeQuery?.entities || [];
  const totalCount = data?.nodeQuery?.count || 0;

  const sectionClassNames = classNames({
    "paragraph paragraph-teaser-list": true,
    "paragraph-teaser-list-blog": true,
  });

  const handlePageClick = (e) => {
    const newOffset =
      (e.selected * content.fieldAnzahlDerAnzuzeigendenI) % totalCount;
    setCurrentOffset(newOffset);
  };

  return (
    <section className={sectionClassNames}>
      {content.fieldHeading && (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>{content.fieldHeading}</h2>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="row">
          <div className="col-12">
            {nodes &&
              nodes.map((item, index) => (
                <TeaserBlog key={index} item={item} />
              ))}
          </div>
        </div>
        {loading && (
          <div className="row">
            <div className="col-12">
              <div className="loader-wrapper">
                <Loader
                  type="TailSpin"
                  color="#0468b1"
                  height={50}
                  width={50}
                  timeout={50000}
                />
              </div>
            </div>
          </div>
        )}
        <ReactPaginate
          breakLabel="..."
          nextLabel="nächste Seite"
          previousLabel="vorherige Seite"
          onPageChange={handlePageClick}
          pageCount={Math.ceil(
            totalCount / content.fieldAnzahlDerAnzuzeigendenI
          )}
          renderOnZeroPageCount={null}
          className="pagination"
        />
      </div>
    </section>
  );
};

ParagraphTeaserListBlog.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    fieldKarussel: PropTypes.bool,
    fieldAnzahlDerAnzuzeigendenI: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldMehrMeldungenButtonZeig: PropTypes.bool,
    fieldTyp: PropTypes.oneOf(["news", "person", "veranstaltung", "all"]),
    fieldHeading: PropTypes.string,
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
    fieldAutmatischOderManuell: PropTypes.oneOf([
      "Automatisch (chronologisch)",
      "Manuell",
    ]),
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    fieldMehrMeldungenButton: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    fieldBeitraegeAll: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.oneOfType([
          teaserNewsPropTypes,
          teaserEventPropTypes,
          teaserPersonPropTypes,
          teaserGeneralPropTypes,
          teaserProjectPropTypes,
        ]),
      })
    ),
    fieldBeitraegeVeranstaltung: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserEventPropTypes,
      })
    ),
    fieldBeitraegeNews: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserNewsPropTypes,
      })
    ),
    fieldBeitraegePerson: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserPersonPropTypes,
      })
    ),
  }),
};

export default ParagraphTeaserListBlog;
