import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import toast from "../../general-components/ui/toast";
import { useDispatch } from "react-redux";
import { authenticationLoginAction } from "../redux-authentication";
import cache from "../../cache";

const PageUserReset = () => {
  const params = useParams();
  const { uid, timestamp, hash } = params;
  const dispatch = useDispatch();
  const [loginState, setLoginState] = useState(null);

  if (!uid || !timestamp || !hash) {
    return <div>Invalid URL</div>;
  }

  useEffect(() => {
    axios
      .post("/api/v1/one-time-login", {
        uid,
        timestamp,
        hash,
      })
      .then((response) => {
        cache.reset();
        toast({
          message: "Sie wurden erfolgreich eingeloggt.",
          type: "success",
        });
        dispatch(authenticationLoginAction(response.data));
        setLoginState(true);
      })
      .catch((error) => {
        console.error(error);
        toast({
          message:
            "Der Link ist ungültig oder wurde bereits verwendet. Bitte fordern Sie einen neuen Link an",
          type: "error",
        });
        setLoginState(false);
      });
  }, []);

  return (
    <article className="system-page">
      <div className="container">
        {loginState === null && (
          <div className="row">
            <div className="col-12 text">Wird eingeloggt...</div>
          </div>
        )}
        {loginState === false && (
          <div className="row">
            <div className="col-12 text">
              <h1>Ungültiger Link</h1>
              <p>
                Der Link ist ungültig oder wurde bereits verwendet. Bitte
                fordern Sie einen neuen Link an.
              </p>
            </div>
          </div>
        )}
        {loginState === true && <Redirect to="/intern/set-password" />}
      </div>
    </article>
  );
};

export default PageUserReset;
