import React from "react";
import FormSetPassword from "../components/form-set-password";
import { useSelector } from "react-redux";
import ExecutionEnvironment from "exenv";
import { Redirect } from "react-router-dom";

const PageSetPassword = () => {
  const auth = useSelector((state) => state.auth);
  const adminApp = useSelector((state) => state.appStore.adminApp);

  if (
    !adminApp &&
    !auth.userLoginData.logout_token &&
    ExecutionEnvironment.canUseDOM
  ) {
    return <Redirect to={"/intern/login?destination=" + location.pathname} />;
  }

  return (
    <article className="system-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
            <h1>Neues Passwort festlegen</h1>
            <p>
              Sie wurden über einen einmaligen Link eingeloggt. Bitte vergeben
              Sie ein neues Passwort, damit Sie sich bei Ihrem nächsten Besuch
              anmelden können.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4">
            <FormSetPassword />
          </div>
        </div>
      </div>
    </article>
  );
};

export default PageSetPassword;
