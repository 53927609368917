import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import usePageContext from "../../../../hooks/usePageContext";

/**
 * A module representing a text paragraph.
 */
const ParagraphText = ({ content }) => {
  const pageContext = usePageContext();
  const sectionClassNames = classNames({
    "paragraph paragraph-text": true,
    "background-color": content.fieldFarbigHinterlegen,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div
            className={classNames({
              "col-wrapper": true,
              "col-wrapper col-12 col-lg-8 col-xl-6": pageContext === "public",
              "col-wrapper col-12 col-lg-10": pageContext === "intern",
            })}
          >
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: content.fieldText.processed,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphText.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldFarbigHinterlegen: PropTypes.bool,
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ParagraphText;
