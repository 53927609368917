import React, { useRef, useState } from "react";
import useAuthentication from "../use-authentication";
import { FormattedMessage } from "react-intl";

const PASS_VALIDATION_REGEX = [
  { regex: /.{8,}/ }, // min 8 letters,
  { regex: /.{16,}/ }, // min 16 letters,
  { regex: /[0-9]/ }, // numbers from 0 - 9
  { regex: /[a-z]/ }, // letters from a - z (lowercase)
  { regex: /[A-Z]/ }, // letters from A-Z (uppercase),
  { regex: /[^A-Za-z0-9]/ }, // special characters
];
const PASS_VALIDATION_MAX_STRENGTH = PASS_VALIDATION_REGEX.length;

const FormResetPassword = () => {
  const { resetPassword } = useAuthentication();
  const resetPasswordForm = useRef();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const validatePasswordStrength = (e) => {
    const password = e.target.value;

    let strength = 0;

    PASS_VALIDATION_REGEX.forEach((rule) => {
      if (rule.regex.test(password)) {
        strength++;
      }
    });

    setPasswordStrength(strength);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const formData = new FormData(resetPasswordForm.current);

    const email = formData.get("email");
    const passwordTmp = formData.get("password-tmp");
    const passwordNew = formData.get("password-new");

    if (passwordStrength < 3) {
      return setError(
        "Das Passwort ist zu schwach. Es sollte mindestens 3 der folgenden Kriterien erfüllen: mindestens 8 Zeichen, mindestens eine Zahl, mindestens ein Großbuchstabe, mindestens ein Kleinbuchstabe, mindestens ein Sonderzeichen."
      );
    }

    if (email === "" || passwordTmp === "" || passwordNew === "") {
      return setError("Bitte füllen Sie alle Felder aus.");
    }

    setLoading(true);
    const response = await resetPassword(email, passwordTmp, passwordNew);
    if (response.status !== 200) {
      setError(
        "Fehler beim Zurücksetzen des Passworts."
      );
    }
    setLoading(false);
  };

  return (
    <div className="form-reset-password">
      <form onSubmit={submitHandler} ref={resetPasswordForm}>
        <fieldset className="form-group">
          <label htmlFor="email">
            <FormattedMessage id="authentication.email" />
            <span className="required" />
          </label>
          <input type="text" name="email" id="email" className="form-control" />
        </fieldset>
        <fieldset className="form-group">
          <label htmlFor="password-tmp">
            <FormattedMessage id="authentication.password_tmp" />
            <span className="required" />
          </label>
          <input
            type="password"
            name="password-tmp"
            id="password-tmp"
            className="form-control"
          />
        </fieldset>
        <fieldset className="form-group">
          <label htmlFor="password-new">
            <FormattedMessage id="authentication.password_new" />
            <span className="required" />
          </label>
          <input
            type="password"
            name="password-new"
            id="password-new"
            className="form-control"
            onChange={validatePasswordStrength}
          />
          <div
            className="password-strength"
            style={{
              "--password-strength":
                (passwordStrength / PASS_VALIDATION_MAX_STRENGTH) * 100 + "%",
            }}
          />
        </fieldset>
        {error && <div className="error-msg">{error}</div>}
        <button type="submit" className="btn btn-primary">
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
          <FormattedMessage id="authentication.change_password" />
        </button>
      </form>
    </div>
  );
};

export default FormResetPassword;
