import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import useAuthentication from "../use-authentication";
import LogoutButton from "./logout-button";

const UserStatusBlock = ({ ignoreOfflineState }) => {
  const { auth } = useAuthentication();
  const loggedIn = !!auth?.userLoginData.logout_token;

  const expirationDate = moment.unix(auth?.userLoginData?.jwt?.exp);

  const getTimeLeft = () => expirationDate.diff(moment(), "seconds");

  const [timeLeft, setTimeLeft] = useState(getTimeLeft());

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimeLeft(getTimeLeft());
    }, 1000);

    return () => clearTimeout(timeout);
  });

  return (
    <div className="user-status-block">
      {loggedIn && (
        <>
          <p>
            Sie sind angemeldet als{" "}
            <b>{auth.userLoginData?.current_user?.name}</b>.<br />
            Sie werden{" "}
            <b>{moment.duration(timeLeft, "seconds").humanize(true)}</b>{" "}
            automatisch ausgeloggt.
          </p>
          <nav>
            <ul>
              <li>
                <Link to="/intern/start">Zum Dashboard</Link>
              </li>
              <li>
                <LogoutButton />
              </li>
            </ul>
          </nav>
        </>
      )}
      {!loggedIn && !ignoreOfflineState && (
        <>
          <p>Sie sind nicht angemeldet.</p>
          <nav>
            <ul>
              <li>
                <Link to="/intern/login">Login</Link>
              </li>
            </ul>
          </nav>
        </>
      )}
    </div>
  );
};

export default UserStatusBlock;
