import React from "react";

const PageWaitForActivation = () => {
  return (
    <article className="system-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="text">
              <h1>Ihre Registrierung wird nun bearbeitet</h1>
              <p>
                vielen Dank für die Registrierung in unserem Kundenbereich. Ihr
                Antrag für ein Benutzerkonto wird aktuell geprüft. Sobald die
                Freigabe erfolgt ist, erhalten Sie eine weitere E-Mail mit
                Anweisungen zum Login.
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default PageWaitForActivation;
