import React, { useState } from "react";
import useAuthentication from "../use-authentication";
import axios from "axios";
import toast from "../../general-components/ui/toast";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * Password schema for validation.
 */
const passwordSchema = yup.object({
  password: yup
    .string()
    .required("authentication.validation.missing_password")
    .min(8, "authentication.validation.password_too_short"),
  passwordRepeat: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "authentication.validation.passwords_not_matching"
    ),
});

const FormSetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { auth } = useAuthentication();
  const history = useHistory();
  const intl = useIntl();

  const submitHandler = async (e) => {
    e.preventDefault();
    setError(null);

    // Get form data
    const formData = new FormData(e.target);
    const password = formData.get("password");
    const passwordRepeat = formData.get("password-repeat");

    // Validate form data
    try {
      await passwordSchema.validate(
        {
          password,
          passwordRepeat,
        },
        { abortEarly: false }
      );
    } catch (error) {
      setError({
        validationErrors: error.errors,
      });
      return;
    }

    setLoading(true);

    // Send request to set password
    axios
      .post("/api/v1/set-password", {
        uid: auth.userLoginData?.current_user?.uid,
        pass1: password,
        pass2: passwordRepeat,
        access_token: auth.userLoginData?.access_token,
      })
      .then((response) => {
        toast({
          message: "Passwort erfolgreich gesetzt.",
          type: "success",
        });
        setLoading(false);
        history.push("/intern/start");
      })
      .catch((error) => {
        console.error(error);
        setError(error.response.data.error);
        toast({
          message: intl.formatMessage({ id: "password_error." + error.response.data.error }),
          type: "error",
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <form onSubmit={submitHandler}>
        <fieldset className="form-group">
          <label htmlFor="password">
            <FormattedMessage id="authentication.password" />
            <span className="required" />
          </label>
          <input
            className="form-control"
            type="password"
            name="password"
            id="password"
          />
        </fieldset>
        <fieldset className="form-group">
          <label htmlFor="password-repeat">
            <FormattedMessage id="authentication.password_repeat" />
            <span className="required" />
          </label>
          <input
            className="form-control"
            type="password"
            name="password-repeat"
            id="password-repeat"
          />
        </fieldset>
        {error && (
          <div className="error-msg d-flex flex-column">
            {error.validationErrors &&
              error.validationErrors.map((error) => (
                <span key={error}>
                  <FormattedMessage id={error} />
                </span>
              ))}
          </div>
        )}
        <button className="btn btn-primary" type="submit">
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Passwort festlegen
        </button>
      </form>
    </div>
  );
};

export default FormSetPassword;
