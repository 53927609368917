import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import axios from "axios";
import YAML from "yaml";

import ErrorFocus from "./elements/error-focus";
import LoadingIndicator from "../../../../general-components/loading-indicator";
import webformQuery from "./webform.graphql";
import submitMutation from "./submitMutation.graphql";
import { restHostBackend } from "../../../../config";
import WebformElements from "./webform-elements";
import { states } from "./states";
import ParagraphsSwitch from "../../paragraphs-switch";
import CaptchaField from "./elements/captcha-field";

const mapStateToProps = (reduxStore) => ({ reduxStore });
const webformElementsNotProcess = ["webform_actions", "hidden"];
export const webformElementsCustomExcluded = [""];
// @todo Add details.
const webformElementsContainer = [
  "webform_custom_composite",
  "webform_address_composite",
  "webform_flexbox",
  "container",
  "fieldset",
];
const webFormElementsNoLevel = [
  "webform_flexbox",
  "fieldset",
  "container",
  "details",
];

export const getValidationSchema = (item, values = null) => {
  let itemSchema;
  switch (item.type) {
    case "webform_custom_composite":
      itemSchema = yup.array();
      break;

    case "textfield":
    case "textarea":
      itemSchema = yup.string();

      if (item.maxLength) {
        itemSchema.max(item.maxLength);
      }
      if (item.minLength) {
        itemSchema.min(item.minLength);
      }

      break;

    // @todo Make date more robust.
    case "date":
      itemSchema = yup.string();
      break;

    case "url":
      itemSchema = yup.string().matches(
        // @see https://stackoverflow.com/a/68002755
        /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
        "Bitte geben Sie eine valide URL ein!"
      );
      break;

    case "email":
      itemSchema = yup
        .string()
        .email("Bitte geben Sie eine valide E-Mail Adresse ein!");
      break;

    case "phone":
      itemSchema = yup.string().phone();
      break;

    case "number":
      // @todo min, max, step.
      itemSchema = yup.number();
      break;

    case "managed_file":
      itemSchema = yup.string();
      break;

    case "radios":
      itemSchema = yup.string();
      break;

    case "checkbox":
      itemSchema = yup.boolean();
      break;

    case "checkboxes":
      itemSchema = yup.array();
      break;

    // @todo Checkboxes limit not working in graphql_webform.
    //case 'checkboxes':
    case "select":
    case "webform_entity_select":
    case "webform_term_select":
      itemSchema = yup.array();

      if (!!item.multiple && item.multiple.limit && item.multiple.message) {
        itemSchema = itemSchema.max(
          item.multiple.limit,
          item.multiple.message
            ? item.multiple.message
            : `Maximal ${item.multiple.limit} Werte sind zulässig!`
        );
      }

      if (item.multiple === null) {
        itemSchema = yup.string();
      }

      break;
  }

  // Mark as not required if there are 'visible' states, in that case
  // the logic is handled via the required prop on the field...
  // ...which does not work, so the mutations returns an error which
  // is shown to the user.
  let visible = true;
  if (values) {
    visible = states(item.states, values).visible;
  }

  if (
    (!!item.required && item.states === null && !values) ||
    (!!item.required && visible && values)
  ) {
    const requiredMessage = item.required.message
      ? item.required.message
      : `${item.title} ist ein Pflichtfeld!`;

    if (item.type === "checkbox") {
      itemSchema = itemSchema.oneOf([true], requiredMessage);
    } else {
      itemSchema = itemSchema.required(requiredMessage);
    }
  }

  return itemSchema;
};

/**
 * Used to generate the initialValues.
 *
 * @param item
 * @returns {*[]|string|{}[]|*}
 */
export const getInitialValue = (item) => {
  let subElements = item.elements;

  if (item.type === "webform_address_composite") {
    const compositeSettings = JSON.parse(item.compositeSettings);

    if (compositeSettings.default_value) {
      subElements = item.elements.map((addressElement) => {
        if (
          Object.prototype.hasOwnProperty.call(
            compositeSettings.default_value,
            addressElement.id
          )
        ) {
          return {
            ...addressElement,
            defaultValue: [compositeSettings.default_value[addressElement.id]],
          };
        }

        return addressElement;
      });
    }
  }

  if (webformElementsContainer.includes(item.type)) {
    // Composite or container elements.
    let containerElements = {};

    !!subElements?.length &&
      subElements.forEach((containerItem) => {
        // Do not process these element types.
        if (
          webformElementsNotProcess.includes(containerItem.type) ||
          webformElementsCustomExcluded.includes(containerItem.id)
        ) {
          return;
        }

        containerElements[containerItem.id] = getInitialValue(containerItem);
      });

    return item.type === "webform_address_composite" ||
      webFormElementsNoLevel.includes(item.type) ||
      (item.type === "webform_custom_composite" && item.multiple === null)
      ? containerElements
      : [containerElements];
  } else {
    if (item.defaultValue && item.defaultValue.length) {
      return item.defaultValue[0];
    } else {
      if (item.type === "checkbox") {
        return false;
      }

      return [
        "select",
        "webform_term_select",
        "webform_entity_select",
        "checkboxes",
      ].includes(item.type) && !!item.multiple
        ? []
        : "";
    }
  }
};

class ParagraphFormular extends Component {
  state = {
    submitResponse: null,
    token: "",
    defaultInitialValues: this.props.defaultInitialValues,
  };

  formContainer = React.createRef();

  componentDidMount() {
    axios
      .get(`${restHostBackend}/session/token`)
      .then((response) => {
        // Save Session Token to redux store.
        this.setState({
          token: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  submitForm = async (values, setSubmitting, resetForm) => {
    const formData = {
      webform_id: this.props.content.fieldFormularAuswahl.targetId,
      data: values,
      sid: this.props.submissionId,
    };

    axios.post("/api/v1/webform", formData).then((response) => {
      this.setState({
        submitResponse: response.data.submitForm,
      });

      if (response.data.submitForm.submission.data) {
        this.setState({
          defaultInitialValues: JSON.parse(
            response.data.submitForm.submission.data
          ),
        });
      }

      // Success.
      if (response.data.submitForm.submission) {
        if (!this.props.defaultInitialValues) {
          resetForm();
        }

        this.formContainer.current.scrollIntoView(true);
      }

      // Error.
      if (response.data.submitForm.errors.length) {
        this.formContainer.current.scrollIntoView(true);
      }
    });

    /* this.props.client
      .mutate({
        mutation: submitMutation,
        variables: {
          values: JSON.stringify(formData),
        },
      })
      .then((response) => {
        console.log(response.data);
        setSubmitting(false);

        this.setState({
          submitResponse: response.data.submitForm,
        });

        if (response.data.submitForm.submission.data) {
          this.setState({
            defaultInitialValues: JSON.parse(
              response.data.submitForm.submission.data
            ),
          });
        }

        // Success.
        if (response.data.submitForm.submission) {
          if (!this.props.defaultInitialValues) {
            resetForm();
          }

          this.formContainer.current.scrollIntoView(true);
        }

        // Error.
        if (response.data.submitForm.errors.length) {
          this.formContainer.current.scrollIntoView(true);
        }
      }); */
  };

  render() {
    if (this.props.data.loading) {
      return <LoadingIndicator />;
    }

    if (this.props.data.error) {
      return <div>{this.props.data.error.message}</div>;
    }

    let initialValues = {},
      validationSchema = {};

    !!this.props.data.webformById.elements?.length &&
      this.props.data.webformById.elements?.forEach((item) => {
        // Do not process these element types.
        if (
          webformElementsNotProcess.includes(item.type) ||
          webformElementsCustomExcluded.includes(item.id)
        ) {
          return;
        }

        // Get initial values.
        if (webFormElementsNoLevel.includes(item.type)) {
          // For these elements, the subelements are not nested into their
          // parents.
          !!item.elements?.length &&
            item.elements.forEach((rootItem) => {
              if (webFormElementsNoLevel.includes(rootItem.type)) {
                initialValues = {
                  ...initialValues,
                  ...getInitialValue(rootItem),
                };
              } else {
                initialValues = {
                  ...initialValues,
                  [rootItem.id]: getInitialValue(rootItem),
                };
              }
            });
        } else {
          initialValues = {
            ...initialValues,
            [item.id]: getInitialValue(item),
          };
        }

        // Validation schema.
        if (webFormElementsNoLevel.includes(item.type)) {
          !!item.elements?.length &&
            item.elements.forEach((rootItem) => {
              validationSchema = {
                [rootItem.id]: getValidationSchema(rootItem),
                ...validationSchema,
              };
            });
        } else {
          validationSchema = {
            [item.id]: getValidationSchema(item),
            ...validationSchema,
          };
        }
      });

    const formParagraphDefaultData = this.props.content.fieldFormularAuswahl
      .defaultData
      ? YAML.parse(this.props.content.fieldFormularAuswahl.defaultData)
      : {};

    return (
      <div className={`paragraph webform-paragraph`}>
        <Formik
          initialValues={
            this.state.defaultInitialValues
              ? {
                  ...initialValues,
                  ...formParagraphDefaultData,
                  ...this.state.defaultInitialValues,
                }
              : { ...initialValues, ...formParagraphDefaultData }
          }
          validationSchema={yup.lazy((values) => {
            this.props.data.webformById.elements?.forEach((item) => {
              // Do not process these element types.
              if (webformElementsNotProcess.includes(item.type)) {
                return;
              }

              // Validation schema.
              if (webFormElementsNoLevel.includes(item.type)) {
                item.elements?.forEach((rootItem) => {
                  validationSchema = {
                    [rootItem.id]: getValidationSchema(rootItem, values),
                    ...validationSchema,
                  };
                });
              } else {
                validationSchema = {
                  [item.id]: getValidationSchema(item, values),
                  ...validationSchema,
                };
              }
            });

            return yup.object().shape(validationSchema);
          })}
          onSubmit={(values, { setSubmitting, resetForm }) =>
            this.submitForm(values, setSubmitting, resetForm)
          }
        >
          {(formik) => (
            <div
              className={`container webform-container`}
              ref={this.formContainer}
            >
              <div className="row">
                <div className="col-12 col-lg-8 order-2 order-lg-1">
                  {this.state.submitResponse &&
                    !this.state.submitResponse.submission &&
                    this.state.submitResponse.errors.length && (
                      <div
                        className={`${this.props.content.fieldFormularAuswahl.targetId} form-messages`}
                      >
                        <div className="alert alert-danger" role="alert">
                          {this.state.submitResponse.errors.map(
                            (item, index) => (
                              <React.Fragment key={index}>
                                <span>{item}</span>
                                <br />
                              </React.Fragment>
                            )
                          )}
                        </div>
                      </div>
                    )}

                  {this.state.submitResponse &&
                    this.state.submitResponse.submission &&
                    !this.state.submitResponse.errors.length && (
                      <div
                        className={`${this.props.content.fieldFormularAuswahl.targetId} form-messages`}
                      >
                        <div className="alert alert-success" role="alert">
                          <>Formulareingabe erfolgreich gespeichert.</>
                        </div>
                      </div>
                    )}

                  <Form
                    noValidate
                    className={this.props.content.fieldFormularAuswahl.targetId}
                  >
                    <WebformElements
                      formik={formik}
                      items={this.props.data.webformById.elements}
                      token={this.state.token}
                      generatedInitialValues={initialValues}
                    />

                    <ErrorFocus
                      isSubmitting={formik.isSubmitting}
                      errors={formik.errors}
                      isValidating={formik.isValidating}
                    />

                    {formik.isSubmitting && <LoadingIndicator />}
                  </Form>
                </div>
                {!!this.props.content.fieldModules?.length && (
                  <div className="col-12 col-lg-4 order-1 order-lg-2 webform-modules">
                    {this.props.content.fieldModules.map((paragraphItem) => (
                      <ParagraphsSwitch
                        key={paragraphItem.entity.entityId}
                        paragraphItem={paragraphItem.entity}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  }
}

ParagraphFormular.propTypes = {
  client: PropTypes.object.isRequired,
  content: PropTypes.shape({
    fieldFormularAuswahl: PropTypes.shape({
      targetId: PropTypes.string,
      defaultData: PropTypes.string,
    }),
  }),
  data: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    webformById: PropTypes.object,
  }),
  defaultInitialValues: PropTypes.object,
  submissionId: PropTypes.string,
};

export default connect(mapStateToProps)(
  graphql(webformQuery, {
    options: (props) => ({
      variables: { id: props.content.fieldFormularAuswahl.targetId },
    }),
  })(withApollo(ParagraphFormular))
);
