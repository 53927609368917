import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import Menu from "../general-components/menu/menu";
import MegaMenu from "../general-components/menu/mega-menu";
import HeaderMetaLink from "./components/header-meta-link";
import EditButton from "../backend/edit-button";
import UserStatusBlock from "../intern/components/user-status-block";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
});

/**
 * Close on menu link click.
 */
const SimpleNavigation = (props) => {
  const [megaMenuOpen, setMegaMenuOpen] = useState(false);

  const megaMenu = props.products
    .filter((product) => product.fieldMenuItemEnabled)
    .map((product) => ({
      title: product.title,
      text: product.fieldMenuText,
      link: product.entityUrl.path,
      logo: product.fieldLogo,
    }));

  const megaMenuProp = {
    label: "Produkte",
    menu: megaMenu,
    onOpen: () => setMegaMenuOpen(true),
  };

  const adminApp = useSelector((state) => state.appStore.adminApp);

  return (
    <>
      <div className="d-none d-lg-block desktop-header">
        <div className="container">
          {props.headerMetaConfig && (
            <div className="row">
              <EditButton
                adminApp={adminApp}
                customLink="/admin/content/header-meta"
              />
              <div
                className="col-12 header-meta-line"
                onMouseEnter={() => setMegaMenuOpen(false)}
              >
                {props.headerMetaConfig.fieldPhone && (
                  <HeaderMetaLink
                    url={props.headerMetaConfig.fieldPhone.url}
                    type="phone"
                  />
                )}
                {props.headerMetaConfig.fieldContact && (
                  <HeaderMetaLink
                    url={props.headerMetaConfig.fieldContact.url}
                    type="contact"
                  />
                )}
                {props.headerMetaConfig.fieldSupport && (
                  <HeaderMetaLink
                    url={props.headerMetaConfig.fieldSupport.url}
                    type="support"
                  />
                )}
                {props.headerMetaConfig.fieldLogin && (
                  <HeaderMetaLink
                    url={props.headerMetaConfig?.fieldLogin.url}
                    type="login"
                  >
                    <UserStatusBlock />
                  </HeaderMetaLink>
                )}
                <div
                  className="header-meta-text"
                  dangerouslySetInnerHTML={{
                    __html: props.headerMetaConfig?.fieldMetaLine.processed,
                  }}
                />
              </div>
            </div>
          )}
          <div className="main-menu-row">
            <nav className="main-menu-wrapper">
              <Menu
                items={props.mainMenuQuery.menuByName.links}
                megaMenu={megaMenuProp}
                effects={true}
                onHover={() => setMegaMenuOpen(false)}
              />
            </nav>
            <div className="logo-wrapper">
              <Link to="/">
                <img src="/dr-malek-logo.png" alt="" />
              </Link>
            </div>
          </div>
        </div>
        <div className="mega-menu-wrapper">
          <MegaMenu
            open={megaMenuOpen}
            menu={megaMenu}
            onClose={() => setMegaMenuOpen(false)}
          />
        </div>
      </div>
    </>
  );
};

SimpleNavigation.propTypes = {
  mainMenuQuery: PropTypes.object,
  products: PropTypes.array,
  headerMetaConfig: PropTypes.object,
};

export default connect(mapStateToProps)(SimpleNavigation);
