import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { PauseCircleFilled, PlayCircleFilled } from "@mui/icons-material";

class ComponentVideo extends React.Component {
  state = {
    videoPlaying: false,
  };

  videoSection = React.createRef();

  playPauseVideo = () => {
    const video = this.videoSection.current.querySelectorAll("video")[0];

    if (video.paused) {
      // Play the video
      video.play();

      // Update the button text to 'Pause'
      this.setState({ videoPlaying: true });
    } else {
      // Pause the video
      video.pause();

      // Update the button text to 'Play'
      this.setState({ videoPlaying: false });
    }
  };

  handleKeyDownPlayPauseButton = (event) => {
    switch (event.keyCode) {
      case 32: // Space
        this.playPauseVideo();
        break;
    }
  };

  // Keyboard Controlling of the Video Section
  handleKeyDownVideo = (event) => {
    const video = event.target;

    switch (event.keyCode) {
      case 32: // Space
        if (video.paused) {
          video.play();
          // Update the button text to 'Pause'
          this.setState({ videoPlaying: true });
        } else {
          video.pause();
          // Update the button text to 'Play'
          this.setState({ videoPlaying: false });
        }
        break;
      case 37: // Left arrow
        video.currentTime -= 10;
        break;
      case 39: // Right arrow
        video.currentTime += 10;
        break;
      case 38: // Up arrow
        video.volume += 0.1;
        break;
      case 40: // Down arrow
        video.volume -= 0.1;
        break;
      case 77: // M
        if (video.muted) {
          video.muted = false;
        } else {
          video.muted = true;
        }
        break;
    }
  };

  render() {
    return (
      <section
        className={classNames({
          [this.props.sectionClassNames]: true,
          "video-playing": this.state.videoPlaying,
        })}
        ref={this.videoSection}
      >
        <div className="container">
          <div className="row">
            <div
              className={classNames({
                "local-video": true,
                "col-12 col-lg-8":
                  this.props.content.fieldGroesse === "text-aligned",
                "col-12": this.props.content.fieldGroesse === "full-size",
              })}
            >
              {Boolean(this.props.content.fieldVideoFileMedia) && (
                <>
                  <video
                    src={
                      this.props.content.fieldVideoFileMedia.entity
                        .fieldMediaVideoFile.entity.url
                    }
                    tabIndex={0}
                    controls={false}
                    aria-controls="playpause"
                    onKeyDown={this.handleKeyDownVideo}
                    onEnded={() => this.setState({ videoPlaying: false })}
                    poster={this.props.content.fieldVideoFileMedia.entity.fieldPreview?.entity?.fieldMediaImage?.style?.url || ""}
                  />
                  <div
                    tabIndex={0}
                    className="playpause"
                    role="button"
                    aria-label="Play or pause the video"
                    onClick={() => this.playPauseVideo()}
                    onKeyDown={this.handleKeyDownPlayPauseButton}
                  >
                    {this.state.videoPlaying ? (
                      <PauseCircleFilled fontSize="inherit" htmlColor="#fff" />
                    ) : (
                      <PlayCircleFilled fontSize="inherit" htmlColor="#fff" />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ComponentVideo.propTypes = {
  content: PropTypes.shape({
    /**
     * 0 = 100%, 1 = 50%
     */
    fieldGroesse: PropTypes.oneOf(["0", "1"]),
    fieldVideoFileMedia: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaVideoFile: PropTypes.shape({
          entity: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
  }),
  sectionClassNames: PropTypes.string,
};

export default ComponentVideo;
