import React from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import EditButton from "../../../backend/edit-button";
import { useSelector } from "react-redux";

const TeaserProduct = ({ content, location }) => {
  const imgData = content.fieldTeaserbild?.entity.fieldMediaImage;
  const imgName = content.fieldTeaserbild?.entity.name;

  const adminApp = useSelector((state) => state.appStore.adminApp);

  const title = content.title?.replace("M3", "<sup>M3</sup>");

  return (
    <article className="node node-teaser teaser-product">
      <EditButton
        adminApp={adminApp}
        entityId={content.entityId}
        destinationRoute={location.pathname}
      />
      <Link to={content.entityUrl.path} className="card">
        <div className="card-head">
          {imgData && (
            <img
              className="background-image"
              src={imgData.style.url}
              alt={imgData.alt || imgName}
              width={imgData.style.width}
              height={imgData.style.height}
            />
          )}
          <div className="logo-wrapper">
            <img
              src={content.fieldLogo?.entity?.fieldMediaImage1?.entity?.url}
              alt="Logo"
            />
          </div>
          <div className="title-wrapper">
            <h3 dangerouslySetInnerHTML={{ __html: title }} />
          </div>
        </div>
        <div className="card-body">
          <div
            dangerouslySetInnerHTML={{
              __html: content.fieldTeasertext.processed,
            }}
          />
        </div>
        <div className="card-footer">
          <div className="btn btn-primary">Mehr</div>
        </div>
      </Link>
    </article>
  );
};

TeaserProduct.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    entityUrl: PropTypes.shape({
      path: PropTypes.string,
    }),
    title: PropTypes.string,
    fieldTeaserbild: PropTypes.object,
    fieldTeasertext: PropTypes.shape({
      processed: PropTypes.string,
    }),
  }),
};

export default withRouter(TeaserProduct);
