import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import LoadingIndicator from "../general-components/loading-indicator";
import blogQuery from "./blog.graphql";
import { useQuery } from "@apollo/client";
import moment from "moment";
import Error404 from "../system/404";
import { Fade } from "react-awesome-reveal";
import Hyphenate from "../general-components/hyphenate";
import { hyphenateSync as hyphenate } from "hyphen/de";
import { Redirect, Link, useLocation } from "react-router-dom";
import ExecutionEnvironment from "exenv";
import useAuthentication from "../intern/use-authentication";

const Blog = () => {
  /* componentDidMount() {
    i18nActionHelper(this.props, this.props.dispatch);
  } */

  /* componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.data.route) !==
      JSON.stringify(prevProps.data.route)
    ) {
      i18nActionHelper(this.props, this.props.dispatch);
    }
  } */

  const location = useLocation();

  const reduxStore = useSelector((reduxStore) => ({
    microSite: reduxStore.appStore.microSite,
    microSiteFrontPage: reduxStore.appStore.microSiteFrontPage,
  }));

  const reducedPath =
    location.pathname === "/" &&
    reduxStore.microSite &&
    reduxStore.microSiteFrontPage !== ""
      ? reduxStore.microSiteFrontPage
      : location.pathname;

  const { data, loading, error } = useQuery(blogQuery, {
    variables: {
      path: reducedPath,
    },
  });

  const { auth } = useAuthentication();

  const content = data?.route?.entity;

  if (loading) return <LoadingIndicator />;
  if (!content) return <Error404 />;
  if (error) return <p>500 Error</p>;

  const published = content.status ? "node-published" : "node-unpublished";

  if (
    !auth.userLoginData.logout_token &&
    ExecutionEnvironment.canUseDOM &&
    content.entityBundle === "interne_seite"
  ) {
    return <Redirect to="/intern/login" />;
  }

  return (
    <article
      className={`node-${content.entityBundle} node-${content.entityId} node-full-page ${published}`}
    >
      <Helmet>
        <title>{`${content.entityLabel} | Dr. Malek Logistiksoftware`}</title>
      </Helmet>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <section className="content-wrap">
        <Fade triggerOnce>
          <section className="section-head">
            <div className="container">
              <div className="row">
                <div className="col-12 col-xl-10">
                  <div className="small meta">
                    <div className="tags">
                      {content.fieldBlogTags.map((tag) => (
                        <span key={tag.entity.tid} className="tag">
                          {tag.entity.name}
                        </span>
                      ))}
                    </div>
                    <div>
                      {moment(content.entityCreated).format(
                        "dddd, DD. MMMM YYYY"
                      )}
                    </div>
                  </div>
                  <h1>{content.title}</h1>
                </div>
              </div>
            </div>
          </section>
        </Fade>

        {content.fieldSummary && (
          <Fade triggerOnce>
            <section className="section-summary">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-xl-10">
                    <div className="hero text">
                      <p>
                        <Hyphenate>{content.fieldSummary}</Hyphenate>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Fade>
        )}

        <Fade triggerOnce>
          <section className="section-text">
            <div className="container">
              <div className="row">
                <div className="col-12 col-xl-10">
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: hyphenate(content.fieldText.processed, {
                        minWordLength: 5,
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        </Fade>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Link to="/intern/blog" className="btn btn-primary btn-back">
                  Zurück zum Blog
                </Link>
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* {this.props.pagerFullPageContextId &&
          this.props.pagerConfig.filter(
            (config) => config.id === this.props.pagerFullPageContextId
          ).length > 0 && (
            <PagerFullPage
              config={
                this.props.pagerConfig.filter(
                  (config) => config.id === this.props.pagerFullPageContextId
                )[0]
              }
              currentId={this.props.data.route.entity.entityId}
            />
          )} */}
    </article>
  );
};

Blog.propTypes = {
  data: PropTypes.object,
};

export default Blog;
