import React, { useRef, useState } from "react";
import useAuthentication from "../use-authentication";
import { FormattedMessage } from "react-intl";

const FormForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const forgotPasswordForm = useRef();

  const { forgotPassword } = useAuthentication();

  const submitHandler = async (e) => {
    e.preventDefault();
    setError(false);
    const formData = new FormData(forgotPasswordForm.current);

    const email = formData.get("email");

    if (email === "") {
      return setError("Bitte füllen Sie alle Felder aus.");
    }

    setLoading(true);
    const response = await forgotPassword(email);
    if (response.status !== 200) {
      setError(
        "Fehler beim Zurücksetzen des Passworts. Ist die E-Mail-Adresse korrekt?"
      );
    }
    setLoading(false);
  };

  return (
    <div className="form-forgot-password">
      <form onSubmit={submitHandler} ref={forgotPasswordForm}>
        <fieldset className="form-group">
          <label htmlFor="email">
            <FormattedMessage id="authentication.email" />
            <span className="required" />
          </label>
          <input type="text" name="email" id="email" className="form-control" />
        </fieldset>
        {error && <div className="error-msg">{error}</div>}
        <button type="submit" className="btn btn-primary">
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
          <FormattedMessage id="authentication.reset_password" />
        </button>
      </form>
    </div>
  );
};

export default FormForgotPassword;
