import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const HeaderMetaLink = ({ url, type, children }) => {
  const icon = <span className={`icon icon-${type}`} />;

  const [childrenOpen, setChildrenOpen] = useState(false);

  if (url.routed)
    return (
      <span
        onMouseEnter={() => setChildrenOpen(true)}
        onMouseLeave={() => setChildrenOpen(false)}
      >
        <Link to={url.path}>{icon}</Link>
        {childrenOpen && children}
      </span>
    );

  return (
    <a
      title={type}
      href={url.path}
      onMouseEnter={() => setChildrenOpen(true)}
      onMouseLeave={() => setChildrenOpen(false)}
    >
      {icon}
      {childrenOpen && children && <div>{children}</div>}
    </a>
  );
};

HeaderMetaLink.propTypes = {
  url: PropTypes.shape({
    path: PropTypes.string.isRequired,
    routed: PropTypes.bool.isRequired,
  }),
  type: PropTypes.oneOf(["phone", "contact", "support", "login"]).isRequired,
  children: PropTypes.node,
};

export default HeaderMetaLink;
