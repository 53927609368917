import React from "react";
import FormLogin from "../components/form-login";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PageLogin = () => {
  return (
    <article className="system-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text">
            <h1>Login</h1>
            <p>
              Bitte geben Sie Ihre Zugangsdaten ein. Sollten Sie noch kein
              Benutzerkonto haben, können Sie sich{" "}
              <Link to="/intern/register">hier registrieren</Link>. Sollten Sie
              Ihr Passwort vergessen haben, können Sie{" "}
              <Link to="/intern/forgot-password">
                hier Ihr Passwort zurücksetzen
              </Link>
              .
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 offset-md-2 col-lg-4 offset-lg-3">
            <FormLogin />
          </div>
        </div>
      </div>
    </article>
  );
};

export default PageLogin;
