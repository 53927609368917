import React from "react";
import PropTypes from "prop-types";
import Image from "../../../../image/image";

const ImageTeaser = ({ content }) => {
  return (
    <div className="image-teaser">
      {content?.fieldImage && (
        <Image data={content.fieldImage.entity.fieldMediaImage} />
      )}
      <div className="teaser-body">
        <h3>{content.fieldTitle}</h3>
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: content.fieldTeasertext }}
        />
      </div>
    </div>
  );
};

export const imageTeaserPropTypes = {
  fieldImage: PropTypes.object,
  fieldTitle: PropTypes.string,
  fieldTeasertext: PropTypes.string,
};

ImageTeaser.propTypes = {
  content: PropTypes.shape(imageTeaserPropTypes),
};

export default ImageTeaser;
