import React from "react";
import FormResetPassword from "../components/form-reset-password";
import useAuthentication from "../use-authentication";
import { Redirect } from "react-router-dom";
import ExecutionEnvironment from "exenv";

const PageResetPassword = () => {
  const { auth } = useAuthentication();

  // Redirect to dashboard if user is already logged in.
  if (auth.userLoginData.logout_token && ExecutionEnvironment.canUseDOM) {
    return <Redirect to="/intern/start" />;
  }

  return (
    <article className="system-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text">
            <h1>Passwort zurücksetzen</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 offset-md-2 col-lg-4 offset-lg-3">
            <FormResetPassword />
          </div>
        </div>
      </div>
    </article>
  );
};

export default PageResetPassword;
