import React from "react";

const FormAccount = () => {
  return (
    <div>
      <form>
        <fieldset className="form-group">
          <label htmlFor="email">E-Mail</label>
          <input
            className="form-control"
            type="email"
            name="email"
            id="email"
          />
        </fieldset>
        <fieldset className="form-group">
          <label htmlFor="password">Passwort</label>
          <input
            className="form-control"
            type="password"
            name="password"
            id="password"
          />
        </fieldset>
      </form>
    </div>
  );
};

export default FormAccount;
