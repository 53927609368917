import React, { Component } from "react";
import { self } from "../config";

class LoadingIndicator extends Component {
  render() {
    return (
      <div className="loading-indicator-wrap">
        <img src={`${self}/dr-malek-logo.svg`} alt="Dr. Malek Logo" />
      </div>
    );
  }
}

export default LoadingIndicator;
