import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import usePageContext from "../../../../hooks/usePageContext";

/**
 * A module representing a hero-text paragraph.
 *
 * @todo In CS we have a field "fieldHintWithColouredBackgro" but we do not use it?
 */
const ParagraphHeroText = ({ content }) => {
  const pageContext = usePageContext();

  const sectionClassNames = classNames({
    "paragraph paragraph-hero-text": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div
            className={classNames({
              "col-12 col-lg-8 col-xl-7": pageContext === "public",
              "col-12 col-lg-10": pageContext === "intern",
            })}
          >
            <div
              className="hero text"
              dangerouslySetInnerHTML={{
                __html: content.fieldText.processed,
              }}
            />
          </div>
        </div>
      </div>

      {/* {content.fieldHint && (
        <div className="hint text">
          <div className="container">
            <div className="row">
              <div className="col-16">
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.fieldHint.processed,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )} */}
    </section>
  );
};

ParagraphHeroText.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldText: PropTypes.shape({
      processed: PropTypes.string.isRequired,
    }),
    fieldHint: PropTypes.shape({
      processed: PropTypes.string,
    }),
  }).isRequired,
};

export default ParagraphHeroText;
