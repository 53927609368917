import React from "react";
import PropTypes from "prop-types";
import Menu from "../../general-components/menu/menu";
import { useQuery } from "@apollo/client";
import menuQuery from "../../general-components/menu-query.graphql";

const UserMenu = ({ content }) => {
  const { data, loading, error } = useQuery(menuQuery, {
    variables: {
      name: "intern",
    },
  });

  const items = data?.menuByName?.links || [];

  return (
    <div className="user-menu">{!!items.length && <Menu items={items} />}</div>
  );
};

UserMenu.propTypes = {};

export default UserMenu;
