import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import { useHistory } from "react-router-dom";
import toast from "../../general-components/ui/toast";

import * as yup from "yup";
import { Link } from "react-router-dom";

const registerSchema = yup.object({
  email: yup
    .string()
    .email("authentication.validation.invalid_email")
    .required("authentication.validation.missing_email"),
  emailRepeat: yup
    .string()
    .email("authentication.validation.invalid_email")
    .required("authentication.validation.missing_email_repeat"),
  firstName: yup
    .string()
    .required("authentication.validation.missing_first_name"),
  lastName: yup
    .string()
    .required("authentication.validation.missing_last_name"),
  company: yup.string().required("authentication.validation.missing_company"),
  username: yup.string().required("authentication.validation.missing_username"),
});

const FormRegisterWithDrupalActivation = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const registerForm = useRef();

  const history = useHistory();

  const submitHandler = async (e) => {
    e.preventDefault();

    const formData = new FormData(registerForm.current);
    const email = formData.get("email");
    const emailRepeat = formData.get("email-repeat");
    const username = formData.get("username");
    const firstName = formData.get("first-name");
    const lastName = formData.get("last-name");
    const company = formData.get("company");

    try {
      await registerSchema.validate(
        {
          email,
          emailRepeat,
          firstName,
          lastName,
          company,
          username,
        },
        { abortEarly: false }
      );
    } catch (error) {
      setError({
        validationErrors: error.errors,
      });
      return;
    }

    if (email !== emailRepeat) {
      setError({
        validationErrors: ["authentication.validation.emails_not_matching"],
      });
      return;
    }

    setLoading(true);

    axios
      .post("/api/v1/register-with-drupal-activation", {
        mail: [email],
        name: [username],
        field_first_name: [firstName],
        field_last_name: [lastName],
        field_company: [company],
      })
      .then((response) => {
        if (response.status !== 200) {
          toast({
            message: "Etwas ist schiefgegangen. Bitte versuchen Sie es erneut.",
            type: "error",
          });
        }
        toast({
          message:
            "Sie wurden erfolgreich registriert. Sie werden automatisch weitergeleitet.",
          type: "success",
        });

        setTimeout(() => {
          return history.push("/intern/wait-for-activation");
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
        toast({
          message: "Etwas ist schiefgegangen. Bitte versuchen Sie es erneut.",
          type: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="form-register">
      <form onSubmit={submitHandler} ref={registerForm}>
        <fieldset className="form-group">
          <label htmlFor="first-name">
            <FormattedMessage id="authentication.first_name" />
            <span className="required" />
          </label>
          <input
            className="form-control"
            type="first-name"
            name="first-name"
            id="first-name"
          />
        </fieldset>

        <fieldset className="form-group">
          <label htmlFor="last-name">
            <FormattedMessage id="authentication.last_name" />
            <span className="required" />
          </label>
          <input
            className="form-control"
            type="last-name"
            name="last-name"
            id="last-name"
          />
        </fieldset>

        <fieldset className="form-group">
          <label htmlFor="company">
            <FormattedMessage id="authentication.company" />
            <span className="required" />
          </label>
          <input
            className="form-control"
            type="company"
            name="company"
            id="company"
          />
        </fieldset>

        <fieldset className="form-group">
          <label htmlFor="username">
            <FormattedMessage id="authentication.username" />
            <span className="required" />
          </label>
          <input
            className="form-control"
            type="username"
            name="username"
            id="username"
          />
        </fieldset>

        <fieldset className="form-group">
          <label htmlFor="email">
            <FormattedMessage id="authentication.email" />
            <span className="required" />
          </label>
          <input className="form-control" type="text" name="email" id="email" />
        </fieldset>

        <fieldset className="form-group">
          <label htmlFor="email-repeat">
            <FormattedMessage id="authentication.email_repeat" />
            <span className="required" />
          </label>
          <input
            className="form-control"
            type="text"
            name="email-repeat"
            id="email-repeat"
          />
        </fieldset>

        {error && (
          <div className="error-msg d-flex flex-column">
            {error.validationErrors &&
              error.validationErrors.map((error) => (
                <span key={error}>
                  <FormattedMessage id={error} />
                </span>
              ))}
          </div>
        )}

        <button className="btn btn-primary" type="submit">
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
          <FormattedMessage id="authentication.register" />
        </button>

        <div className="help-links">
          <p>
            <FormattedMessage id="authentication.already_registered" />{" "}
            <Link to="/intern/login">
              <FormattedMessage id="authentication.login" />
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default FormRegisterWithDrupalActivation;
