import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Menu = ({
  items,
  depth = 1,
  megaMenu = null,
  effects = false,
  ...props
}) => {
  const [activeElement, setActiveElement] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const { pathname } = useLocation();

  const mouseEnterHandler = (i) => {
    setActiveElement(i);
    props.onHover();
  };

  return (
    <ul
      className={classNames({
        [`level-${depth}`]: true,
        [props.ulClassName]: props.ulClassName,
      })}
    >
      {!!items?.length &&
        items.map((item, i) => (
          <React.Fragment key={i}>
            {megaMenu?.label !== item.label && (
              <li
                onMouseEnter={() => effects && mouseEnterHandler(i)}
                onMouseLeave={() => effects && setActiveElement(null)}
                className={classNames({
                  active: pathname === item.url?.path,
                  expanded: activeElement === i,
                  [props.liClassName]: props.liClassName,
                })}
              >
                <>
                  {item.url?.path && (
                    <>
                      <Link
                        to={item.url?.path}
                        onClick={props.onClick || undefined}
                      >
                        {item.label}
                      </Link>
                      {!!item.links?.length && (
                        <Menu
                          depth={depth + 1}
                          items={item.links}
                          onClick={props.onClick}
                        />
                      )}
                    </>
                  )}
                  {!item.url?.path && (
                    <>
                      <span
                        className={classNames({
                          expandable: true,
                          expanded: expanded === item.label,
                        })}
                        onClick={() =>
                          setExpanded((prev) =>
                            prev === item.label ? null : item.label
                          )
                        }
                      >
                        {item.label}
                      </span>
                      {!!item.links?.length && expanded === item.label && (
                        <Menu
                          depth={depth + 1}
                          items={item.links}
                          onClick={props.onClick}
                        />
                      )}
                    </>
                  )}
                </>
              </li>
            )}

            {megaMenu?.label === item.label && (
              <li
                key={i}
                onMouseEnter={megaMenu.onOpen}
                className={classNames({
                  active: pathname.startsWith(item.url?.path),
                  expanded: activeElement === i,
                  [props.liClassName]: props.liClassName,
                })}
              >
                <Link to={item.link || item.url?.path}>{item.label}</Link>
              </li>
            )}
          </React.Fragment>
        ))}
    </ul>
  );
};

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  depth: PropTypes.number,
  megaMenu: PropTypes.object,
  effects: PropTypes.bool,
  className: PropTypes.string,
  onHover: PropTypes.func,
};

export default Menu;
