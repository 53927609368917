import React from "react";
import FormRegisterWithDrupalActivation from "../components/form-register-with-drupal-activation";

const PageRegister = () => {
  return (
    <article className="system-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text">
            <h1>Registrieren</h1>
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 offset-md-2 col-lg-4 offset-lg-3">
            <FormRegisterWithDrupalActivation />
          </div>
        </div>
      </div>
    </article>
  );
};

export default PageRegister;
