import React from "react";
import { Link } from "react-router-dom";

const PageVerifyRegistrationSuccess = () => {
  return (
    <article className="system-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="text">
              <h1>Registrierung erfolgreich</h1>
              <p>
                Ihre Registrierung ist erfolgreich abgeschlossen. Sie können
                sich jetzt einloggen.
                <br />
                <Link to="/intern/login">Zum Login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default PageVerifyRegistrationSuccess;
