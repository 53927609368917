import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link32 } from "@carbon/icons-react";
import usePageContext from "../../../../hooks/usePageContext";

const ParagraphSectionheading = ({ content }) => {
  const pageContext = usePageContext();

  const sectionClassNames = classNames({
    "paragraph paragraph-sectionheading": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row justify-content-between">
          <div
            className={classNames({
              "col-12 col-lg-8": pageContext === "public",
              "col-12 col-lg-10": pageContext === "intern",
            })}
          >
            <h2
              id={content.fieldHeading.toLowerCase().replace(/\W/g, "-")}
              className="section-heading"
            >
              {content.fieldHeading}
            </h2>
          </div>
          {content.fieldSetAnchorLink && (
            <div className="col-1">
              <a
                className="anchor"
                href={`#${content.fieldHeading
                  .toLowerCase()
                  .replace(/\W/g, "-")}`}
              >
                <Link32 />
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

ParagraphSectionheading.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldSetAnchorLink: PropTypes.bool,
  }),
};

export default ParagraphSectionheading;
