import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ChevronDown24, ChevronUp24 } from "@carbon/icons-react";

const ParagraphStrukturierteListe = ({ content }) => {
  const [open, setOpen] = useState(false);

  const sectionClassNames = classNames({
    "paragraph paragraph-strukturierte-liste": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>{content.fieldHeading}</h3>
          </div>
        </div>
        <div className="row">
          {content.fieldAusklappenEinklappen && (
            <div className="col-16 col-md-3 d-md-flex justify-content-end align-items-baseline">
              <div
                className="btn btn-secondary btn-icon"
                onClick={() => setOpen((prev) => !prev)}
              >
                {open ? (
                  <>
                    Einklappen <ChevronUp24 />
                  </>
                ) : (
                  <>
                    Ausklappen <ChevronDown24 />
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        <div
          className={classNames({
            "row content": true,
          })}
        >
          {content.fieldListElements.map((item, i) => (
            <div
              key={i}
              className={classNames({
                "list-item col-12": true,
                "col-md-6 col-xl-4": content.fieldListElements.length > 1,
              })}
            >
              <div className="list-banner">
                <h3>{item.entity.fieldTitle}</h3>
              </div>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: item.entity.fieldText.processed,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

ParagraphStrukturierteListe.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldAusklappenEinklappen: PropTypes.bool,
  }),
};

export default ParagraphStrukturierteListe;
