import React, { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

const MegaMenu = ({ open, menu, onClose }) => {
  const megaMenuRef = useRef();

  return (
    <div
      ref={megaMenuRef}
      className={classNames({
        "mega-menu": true,
        expanded: open,
      })}
      onMouseLeave={onClose}
    >
      <div className="container">
        <nav>
          <ul className="mega-menu-list">
            {menu.map((item, i) => (
              <li key={i} className="mega-menu-item">
                <Link to={item.link}>
                  {item.logo?.entity?.fieldMediaImage1 && (
                    <img
                      className="icon"
                      src={item.logo?.entity?.fieldMediaImage1?.entity?.url}
                      alt=""
                    />
                  )}
                  {item.title}
                  <p>{item.text}</p>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

MegaMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  menu: PropTypes.array.isRequired,
  onClose: PropTypes.func,
};

export default MegaMenu;
